<template>
  <div class="bg">
    <van-form @submit="onSubmit">
      <h1>个人信息</h1>
      <van-field
        v-model="from.username"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="from.username"
        name="手机号"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <h1>药品信息</h1>
      <van-field
        v-model="from.username"
        name="药品名称"
        label="药品名称"
        placeholder="药品名称"
        :rules="[{ required: true, message: '请填写药品名称' }]"
      />
      <van-field
        v-model="from.username"
        name="需求时间"
        label="需求时间"
        placeholder="需求时间"
        :rules="[{ required: true, message: '请填写需求时间' }]"
      />
      <van-field
        v-model="from.username"
        name="需求数量"
        label="需求数量"
        placeholder="需求数量"
        :rules="[{ required: true, message: '请填写需求数量' }]"
      />
      <van-field
        v-model="from.username"
        name="生产厂家"
        label="生产厂家"
        placeholder="生产厂家"
        :rules="[{ required: true, message: '请填写生产厂家' }]"
      />
      <van-field
        v-model="from.message"
        rows="6"
        autosize
        label="备注"
        type="textarea"
        maxlength="500"
        placeholder="备注"
        show-word-limit
      />
      <div class="upload">
        <p><span>图片</span>(最多9张图片)</p>
        <van-uploader v-model="fileList" multiple />
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      from: {
        username: "",
        sex: "",
        orld: "",
        sms: "",
        message: "",
      },
      fileList: [],
      radio: "",
    };
  },
  methods: {
    onSubmit() {
      Toast("提示内容");
    },
    showxl() {
      Dialog.alert({
        title: "标题",
        message: "弹窗内容",
      }).then(() => {
        // on close
      });
    },
    changeradio(name) {},
  },
};
</script>
<style lang="less" scoped>
.bg {
  background: #fff;
  .myradio .van-radio {
    padding-left: 16px;
  }
  .xy {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #1daefc;
  }
  h1 {
    padding: 16px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #444444;
  }
}
.upload {
  text-align: left;
  color: #c1c4c6;
  padding: 16px;
  p {
    color: #c1c4c6;
    margin-bottom: 10px;
    span {
      color: #000;
      margin-right: 16px;
    }
  }
}
</style>
